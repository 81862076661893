<template>
  <div class="promo-codes">
    <h3 class="heading-text">Archvied Promo Codes</h3>
    <template v-if="fetching">
      <div class="overlay">
        <div class="d-flex">
          <b-spinner variant="danger"></b-spinner>
          <span class="ml-4">Fetching...</span>
        </div>
      </div>
    </template>
    <template v-else>
      <div class="row w-100">
        <span class="offset-4 col-4 text-right">Promo Codes Per Page</span>
        <div class="col-4 d-flex justify-content-end">
          <b-form-select
            v-model="perPage"
            :options="perPageOptions"
            class="col-8 w-100"
            @change="perPageChange"
          ></b-form-select>
        </div>
      </div>
      <div class="items">
        <template v-for="code in promoCodes">
          <SinglePromoCode
            :key="code._id"
            :promo="code"
            :archived="false"
            v-if="code"
            @update="updatePromos"
          />
        </template>
      </div>
      <template v-if="count > 0">
        <b-pagination
          v-model="currentPage"
          :total-rows="count"
          :per-page="perPage"
          first-number
          @change="changePage"
        ></b-pagination>
      </template>
    </template>
  </div>
</template>

<script>
import SinglePromoCode from "@/view/pages/promotions/SinglePromoCode";
import { GET_ARCHIVED_PROMOCODES } from "@/core/services/store/promoCodes.module";

import { mapState } from "vuex";
export default {
  components: {
    SinglePromoCode,
  },
  data() {
    return {
      currentPage: 1,
      perPage: 10,
      perPageOptions: [
        { value: 10, text: "10" },
        { value: 25, text: "25" },
        { value: 50, text: "50" },
      ],
    };
  },
  mounted() {
    this.$store.dispatch(GET_ARCHIVED_PROMOCODES, {
      limit: this.perPage,
      offset: this.currentPage - 1,
    });
  },
  methods: {
    async updatePromos(options = {}) {
      const { limit = this.perPage, offset = this.currentPage - 1 } = options;
      await this.$store.dispatch(GET_ARCHIVED_PROMOCODES, { limit, offset });
      this.perPage = limit;
      this.currentPage = offset + 1;
    },
    changePage(val) {
      this.updatePromos({ limit: this.perPage, offset: val - 1 });
    },
    perPageChange(val) {
      this.updatePromos({ offset: this.currentPage - 1, limit: val });
    },
  },
  computed: {
    ...mapState({
      promoCodes: (state) =>
        state.promoCodes.archived.map((i) => {
          i.promoType =
            i.promoType.charAt(0).toUpperCase() + i.promoType.slice(1);
          return i;
        }),
      count: (state) => state.promoCodes.archivedCount,
      fetching: (state) => state.promoCodes.fetchingArchived,
    }),
  },
};
</script>

<style lang="scss" scoped>
.promo-codes {
  h3.heading-text {
    font-size: 28px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
  }
  .overlay {
    width: 100%;
    height: 100%;
    background: rgba(255, 255, 255, 0.5);
    position: absolute;
    display: flex;
    justify-content: center;
    padding: 25px;
    top: 0;
    left: 0;
    z-index: 1;
  }
}
</style>
