<template>
  <div class="single-promotion shadow-sm">
    <div class="upper-block">
      <div class="promotion-block shadow-sm">
        <div class="promotion-text">
          <div class="promotion-heading">Bonus 1</div>
          <div class="promotion-name">50 Plus Orders</div>
        </div>
      </div>
    </div>
    <div class="lower-block">
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Promotion Type</div>
            <div class="info-value">Product Based</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Promotion Placement</div>
            <div class="info-value">Middle</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Discount %</div>
            <div class="info-value">30</div>
          </div>
        </div>
      </div>
      <div class="row">
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">Start Date</div>
            <div class="info-value">Jan 15,2020</div>
          </div>
        </div>
        <div class="col-md-4">
          <div class="info-block">
            <div class="info-head">End Date</div>
            <div class="info-value">Jan 20,2020</div>
          </div>
        </div>
        <div class="col-md-4 d-flex justify-content-center">
          <span
            v-bind:class="!archived ? 'archive' : 'unarchive'"
            class="action-btn"
            >{{ archived ? "Unarchive" : "Archive" }}</span
          >
        </div>
      </div>
    </div>
  </div>
</template>

<script>
export default {
  data() {
    return {};
  },
  props: {
    archived: Boolean,
  },
};
</script>

<style lang="scss" scoped>
.single-promotion {
  width: 525px;
  background: #fff;
  border-radius: 15px;
  border-top-left-radius: 0;
  position: relative;
  margin: 10px;

  .upper-block {
    .promotion-block {
      border-radius: 0 0 10px 10px;
      height: 200px;
      background: #e72a37;
      display: flex;
      align-items: flex-start;
      .promotion-text {
        padding: 15px;
        display: flex;
        flex-direction: column;
        .promotion-heading {
          font-size: 40px;
          line-height: 0.75;
          color: #fff;
        }
        .promotion-name {
          font-size: 40px;
          color: #fff;
        }
      }
    }
  }

  .lower-block {
    padding: 20px;
    .row {
      margin-bottom: 10px;
    }
    .info-block {
      display: flex;
      flex-direction: column;
      .info-head {
        font-weight: 600;
      }
      .info-value {
        color: #bebdbd;
      }
    }
    .action-btn {
      color: #fff;
      cursor: pointer;
      padding: 8px 25px;
      width: 100%;
      text-align: center;
      &.unarchive {
        background: #50a6fa;
      }
      &.archive {
        background: #f8b040;
      }
    }
  }
}
</style>
