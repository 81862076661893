<template>
  <div class="archived-promotions">
    <h3 class="heading-text">Archive</h3>
    <div class="items">
      <SinglePromotion :archived="true" />
      <SinglePromotion :archived="true" />
      <SinglePromotion :archived="true" />
    </div>
  </div>
</template>

<script>
import SinglePromotion from "@/view/pages/promotions/SinglePromotion";
export default {
  components: {
    SinglePromotion,
  },
};
</script>

<style lang="scss" scoped>
.archived-promotions {
  h3.heading-text {
    font-size: 28px;
  }
  .items {
    display: flex;
    flex-wrap: wrap;
  }
}
</style>
